body {
  position: relative;
  min-width: 0;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

@media print {
  html,
  body {
    height: initial;
    overflow: initial;
  }

  div {
    break-inside: avoid;
  }
}

@page {
  size: auto;
}

.wrapped {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ui.disabled.input,
.ui.input:not(.disabled),
input[disabled],
.ui.form.disabled.field,
.ui.form.disabled.fields.field,
.ui.form.field :disabled {
  opacity: 1;
}

.ui.pagination.pointing.secondary.stackable.stackable.menu {
  display: flex;
  flex-wrap: wrap;
}

.fc-day.fc-widget-content.fc-sat,
.fc-day.fc-widget-content.fc-sun {
  background-color: rgba(207, 207, 207, 0.2);
}

.ui.checkbox input[type='checkbox'] {
  position: inherit;
  transform: scale(2, 2);
}

.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-full);
}

iframe {
  width: 100%;
  height: 92.5vh;
}

.fc-toolbar.fc-header-toolbar {
  flex-wrap: wrap;
}

.fc-scroller.fc-day-grid-container .fc-scroller.fc-time-grid-container {
  height: 420px;
  min-height: 420px;
}

@media (max-width: 768px) {
  .ui.modal > .actions {
    margin-bottom: 0.5em;
  }

  .fc-day-number {
    font-size: 16px;
  }
}

.modal-blur {
  filter: blur(0.25rem);
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.7);
}

.ui.inverted.dimmer {
  background-color: rgba(255, 255, 255, 0.6);
}

.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.left-button {
  border-radius: 0.35em 0 0 0.35em !important;
  margin-right: 0 !important;
}

.centre-button {
  border-radius: 0 0 0 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.right-button {
  border-radius: 0 0.35em 0.35em 0 !important;
  margin-left: 0 !important;
}

.ui.dropdown > .text {
  display: flex;
}

@media (min-width: 762px) {
  .ui.fullscreen.modal,
  .ui.fullscreen.scrolling.modal {
    left: 2.5% !important;
  }
}

@media (max-width: 762px) {
  .ui.fullscreen.modal,
  .ui.fullscreen.scrolling.modal {
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
  }
}
