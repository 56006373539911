/* .calendar-dark-theme > .fc-unthemed td {
  border-color: rgba(255, 255, 255, 0.25);
}

.calendar-dark-theme > .fc-unthemed td.fc-today {
  background-color: rgb(25, 37, 103, 0.75);
}

.calendar-dark-theme > .fc-day.fc-widget-content.fc-sat,
.calendar-dark-theme > .fc-day.fc-widget-content.fc-sun {
  background-color: rgba(207, 207, 207, 0.1);
}

.calendar-dark-theme > .ui.page.modals.dimmer {
  background-color: rgba(255, 255, 255, 0.45);
}

.calendar-light-theme > .fc-unthemed td.fc-today {
  background-color: rgba(238, 233, 204, 0.75);
}

.fc-title {
  font-weight: 600;
}

.fc-unthemed .fc-list-heading td {
  background: none;
}

.fc-scroller .fc-list-empty .fc-list-empty-wrap1 .fc-list-empty-wrap1 {
  background: none;
}

.fc-unthemed .fc-list-empty .fc-allow-mouse-resize {
  background: none;
} */

.fc-day-sat,
.fc-day-sun {
  background-color: rgba(207, 207, 207, 0.1);
}

.calendar-light-theme .fc-day-sat,
.calendar-light-theme .fc-day-sun {
  background-color: rgba(207, 207, 207, 0.2);
}

.calendar-light-theme .fc-col-header-cell-cushion,
.calendar-light-theme .fc-daygrid-day-number {
  color: rgba(0, 0, 0, 0.87);
}

.calendar-light-theme > .fc .fc-daygrid-day.fc-day-today {
  background-color: rgb(124, 126, 127, 0.1);
}

.calendar-dark-theme > .fc .fc-daygrid-day.fc-day-today {
  background-color: rgb(124, 126, 127, 0.75);
}

.calendar-dark-theme .fc-col-header-cell-cushion,
.calendar-dark-theme .fc-daygrid-day-number {
  color: rgba(255, 255, 255, 0.75);
}

.calendar-dark-theme > .fc-theme-standard,
.calendar-dark-theme > .fc-theme-standard th {
  border-color: rgba(255, 255, 255, 0.25);
}

.calendar-dark-theme > .fc-theme-standard .fc-scrollgrid {
  border-color: rgba(255, 255, 255, 0.25);
}

.calendar-dark-theme > .fc-theme-standard td {
  border-color: rgba(255, 255, 255, 0.25);
}
