/* Colours from colours.ts, 
  TextColourDark, 
  BackgroundColourDark = '#0b0b0b'
  NeutralColourOneDark = '#3d3d3d'
*/

.dark-theme .rst__rowContents {
  background-color: #0b0b0b !important;
  color: rgba(255, 255, 255, 0.75) !important;
}

.dark-theme .rst__moveHandle {
  background-color: #3d3d3d !important;
}

.dark-theme .rst__lineHalfHorizontalRight::before,
.dark-theme .rst__lineFullVertical::after,
.dark-theme .rst__lineHalfVerticalTop::after,
.dark-theme .rst__lineHalfVerticalBottom::after {
  background-color: white !important;
}
